import React from "react";
import { Link } from "gatsby";
import Layout from "../components/layout";
import Image from "../components/image";
import SEO from "../components/seo";

const sponsors = () => (
  <Layout>
    <SEO title="About Us" />

    <div className="Herosub batslogo">
      <div className="HeroGroupsmall">
        <p>&nbsp;</p>
        <h1>Become a Sponsor</h1>
      </div>
    </div>

    <div className="mainContent">
      <h3 style={{textAlign: "center"}}>
        Bobcats' Vision: To provide a positive, competitive baseball experience
        to the athletes and their famlies while focusing on the fundamentals of
        baseball to enahce the player's abilities for the future
      </h3>&nbsp;<hr></hr>
      <h3 style={{textAlign: "center"}}>Bobcat's Mission: Helping Kids Get to College!</h3>
      
      <p>
        The Bobcats of Northern Virginia baseball team is a group of 13 to18
        year-olds from the Dale City, Manassas, Dumfries, Fairfax and Woodbridge
        areas. Our team is a member of the U.S. Specialty Sports Association
        (USSSA), which are non-profit organizations dedicated exclusively to the
        promotion and development of amateur sports and physical fitness
        programs.
      </p>
      <p>
        The Bobcats are not affiliated with any recreation league; therefore,
        all money comes from the parents. We are an IRS approved 501(c) 3
        non-profit organization, which means your donations are tax deductible.
        We are asking for your financial support to help ensure a successful
        showcase season. All money donated to the team goes to the team.
        Everyone associated with the Bobcats is a volunteer.
      </p>
      <p>
        Donations will be used for registration, ball field maintenance,
        umpires’ fees, tournament fees, uniforms and equipment. In hopes of
        remaining affordable to the parents of each player, it is the goal of
        the coaching staff to have each player able to secure at least one
        sponsor for the team this year. We have several different sponsorship
        levels available for corporate sponsors, or Fan Club Members. Our team
        Tax Id information will be provided on your tax credit receipt.
      </p>
      <p>
        The Bobcats strive to develop a well-rounded team, putting emphasis on
        team unity, sportsmanship, discipline, respect for adults and academics.
        All players are encouraged and rewarded for their focus on education and
        efforts to strive to become stronger individuals.
      </p>
      <p>
        If you have any further questions regarding our organization, please
        contact us at <a className="email"href="mailto:bobcatsnova@gmail.com">bobcatsnova@gmail.com</a>
      </p>
    </div>
  </Layout>
);

export default sponsors;

